import Vue from 'vue';
import Antd from 'ant-design-vue';
import VueI18n from 'vue-i18n';
import Clipboard from 'v-clipboard';
import VueCookies from 'vue-cookies';

import ElementLoading from 'vue-element-loading';
import App from './App.vue';
import router from './router';
import filter from './plugins/filters';
import vn from './lang/vn.json';
import configApp from './config/app';
import 'ant-design-vue/dist/antd.css';

Vue.config.productionTip = false;

Vue.use(VueCookies);
Vue.use(VueI18n);
Vue.use(Clipboard);
Vue.component('VueElementLoading', ElementLoading);

const i18n = new VueI18n({
  locale: 'vn',
  messages: {
    vn,
  },
  fallbackLocale: 'vn',
});

Vue.use(Antd);

Vue.use(filter);

Vue.mixin({
  data() {
    return {
      configApp,
    };
  },
});

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
