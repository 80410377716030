<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
});
</script>

<style>
body,
html {
  height: 100%;
}

#app {
  height: 100%;
}

#wrapper {
  min-height: 100%;
  padding-bottom: 100px;
}

.text-center {
  text-align: center;
}

</style>
