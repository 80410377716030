const loader = {
  addCommas: (num) => (Math.round(num) || 0).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
};

export default {
  install(Vue) {
    Object.keys(loader).forEach((item) => {
      Vue.filter(item, loader[item]);
    });
  },
};
