import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      component: () => import('../components/Layout/Default.vue'),
      children: [{
        path: '/',
        name: 'index',
        component: () => import('../views/Index.vue'),
      }],
    },
  ],
});
