export default {
  title: 'Kênh thanh toán dịch vụ',
  header: 'Kênh thanh toán',
  footer: 'Kênh thanh toán cho các dịch vụ thuộc AutoShopee & Subcheo',
  service: {
    pusher: {
      key: '89368ca13564f7ecb8bc',
      cluster: 'ap1',
      event: 'payment',
    },
  },
  contact: {
    phone: '0901581559 / 0941362912',
    zalo: 'https://zalo.me/0366200750',
  },
  currency: 'VNĐ',
  sound: {
    paymentSuccess: '/audio/success.mp3',
  },
};
